<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-1">
              <div class="col-12 section_title">CREAR ORDEN DE PRODUCCIÓN</div>
            </div>
          </template>

          <template v-slot:end>
            <Button
              label="GUARDAR"
              icon="pi pi-save"
              class="p-button-success mr-2"
              @click="save()"
            />
          </template>
        </Toolbar>

        <div class="">
          <div class="">
            <div class="formgrid grid">
              <div class="field col">
                <label for="client" style="display: block">CLIENTE</label>
                <Dropdown
                  id="client"
                  v-model="form.client"
                  :options="clients"
                  class="w-full"
                  filter="true"
                  filterBy="name"
                  filterPlaceholder="Buscar..."
                  optionLabel="name"
                  optionValue="id"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !form.client }"
                />
              </div>
              <div class="field col">
                <label for="detail">O/C</label>
                <InputText
                  v-model="form.oc"
                  id="detail"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
              <div class="field col">
                <label for="detail">DETALLE</label>
                <InputText
                  v-model="form.obs"
                  id="detail"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
            </div>
          </div>

          <div class="">
            <div class="formgrid grid">
              <div class="field col">
                <label for="detail">CANTIDAD</label>
                <InputText
                  v-model="form.quantity"
                  id="detail"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
              <div class="field col">
                <label for="detail">ENTREGA PROMETIDA</label>
                <input
                  type="date"
                  v-model="form.dateout"
                  class="
                    p-inputtext p-component
                    inputfield
                    w-full
                    inputfield
                    w-full
                  "
                />
              </div>
              <div class="field col">
                <label for="detail">MONEDA</label>
                <SelectButton
                  v-model="form.currency"
                  :options="moneyTypes"
                  optionValue="value"
                  dataKey="value"
                  optionLabel="label"
                >
                </SelectButton>
              </div>
              <div class="field col">
                <label for="detail">TOTAL</label>
                <InputText
                  v-model="form.cdolarpeso"
                  id="detail"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrudService from "./../../services/crud.service.js"
import axios from "axios";

export default {
  data() {
    return {
      routeName: null,      
      form: {
		client: null,
		detail: null,
		dateout: null,
		total: null,
		datein: "2022-03-22",
		material: "-",
		piece: "-",
		tratamiento: 0,
		cdolarpeso: 0,
		currency: 0,
	  },     
      submitted: false,
      validationErrors: null,
	  moneyTypes: [
                {value: 0, label: 'AR$'},
                {value: 1, label: 'U$D'},
                {value: 2, label: 'EUR'}                
				],
      clients: []
    };
  },
	 
  created() {
    // get route name
    this.routeName = this.$route.name;
     

  },
  mounted() {

    CrudService.getCRUD('api/clients').then(
      (data) => (this.clients = data)
    );

  },
  methods: {

    displayErrors(errors) {
      
              for (let key in errors) {

                this.validationErrors.push({ "field": key, "message": errors[key] });

                let error = errors[key];
                this.$toast.add({
                  severity: "error",
                  summary: "Error!",
                  detail: error[0],
                  life: 3000,
                });
              }
    },
    save() {
          this.submitted = true;


          CrudService.createCRUD('api', 'produccion', this.form).then(
            (data) => {
              
            this.validationErrors = [];
            if (data.status == 400) { 

              this.displayErrors(data.data);
              
              } else {

                this.$toast.add({
                  severity: "success",
                  summary: "Éxito!",
                  detail: "Creado correctamente.",
                  life: 3000,
                });
                
                this.$router.push("/produccion");
              }
            }
          );
        }
     
    },

}
</script>